<template>
  <div>
    <Toolbar :handle-delete="del">
      <template slot="left">
        <v-toolbar-title v-if="item">{{
          `${$options.servicePrefix} ${item["@id"]}`
        }}</v-toolbar-title>
      </template>
    </Toolbar>

    <br />

    <div v-if="item" class="table-site-show">
      <v-simple-table>
        <template slot="default">
          <thead>
          </thead>
          <tbody>
            <tr>
              <!-- <tb><strong>{{ $t('slug') }}</strong></tb>
              <tb>
                                    {{ item['slug'] }}
              </tb> -->

              <!-- <tb><strong>{{ $t('nom') }}</strong></tb> -->
              <tb>
                <v-col offset-md="2" cols="10" sm="6" md="6">
                  {{ item["nom"] }}
                </v-col>
              </tb>

              <!-- <tb><strong>{{ $t('adresse') }}</strong></tb> -->
              <tb>
                <v-col offset-md="2" cols="10" sm="6" md="6">
                  {{ item["adresse"] }}
                </v-col>
              </tb>

              <!-- <tb><strong>{{ $t('codePostal') }}</strong></tb> -->
              <tb>
                <v-col offset-md="2" cols="10" sm="6" md="6">
                  {{ item["codePostal"] }}
                </v-col>
              </tb>

              <!-- <tb><strong>{{ $t('ville') }}</strong></tb> -->
              <tb>
                <v-col offset-md="2" cols="10" sm="6" md="6">
                  {{ item["ville"] }}
                </v-col>
              </tb>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </div>

    <Loading :visible="isLoading" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import { mapFields } from "vuex-map-fields";
import Loading from "../../components/Loading";
import ShowMixin from "../../mixins/ShowMixin";
import Toolbar from "../../components/Toolbar";

const servicePrefix = "Site";

export default {
  name: "SiteShow",
  servicePrefix,
  components: {
    Loading,
    Toolbar,
  },
  mixins: [ShowMixin],
  computed: {
    ...mapFields("site", {
      isLoading: "isLoading",
    }),
    ...mapGetters("site", ["find"]),
  },
  methods: {
    ...mapActions("site", {
      deleteItem: "del",
      reset: "reset",
      retrieve: "load",
    }),
  },
};
</script>
